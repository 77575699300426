html, body {
  min-height: 100%;
  font-size: 16px;
}
  
body {
  font-family: 'Open Sans', sans-serif;
  min-height: 100%;
}

h1 {
  font-size: 3rem;
  line-height: 3.9rem;
  font-weight: 600;
}

h2 {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 600;
  text-align: center;
}

h3 {
  font-size: 1.5rem;
  line-height: 3.1rem;
  font-weight: 600;
  text-align: left;
}

h4 {
  font-size: 1.2rem;
  line-height: 2.9rem;
  font-weight: 600;
  color: #4D8DFF;
}

h5 {
  font-weight: 600!important;
  font-size: 1rem!important;
  display: inline;
}

h6 {
  font-weight: 600!important;
  font-size: 1.1rem!important;
  color: #1A2E64!important;
  display: inline;
  padding: 1rem 0;
}

p {
  font-size: 1.5rem;
}

button.simulador-button-primary{
  background-color: #4D8DFF;
  border-color: #4D8DFF;
  height: 40px!important;
}

button.simulador-button-primary:hover{
  background-color: #4DADF7;
  border-color: #4DADF7;
}

button.simulador-button-primary:active{
  background-color: #649b04;
  border-color: #649b04;
}

button.simulador-button{
  background-color: #FF9900;
  border-color: #FF9900;
  height: 40px!important;
}

button.simulador-button:hover{
  background-color: #FAB005;
  border-color: #FAB005;
}

.grecaptcha-badge {
    width: 70px !important;
    overflow: hidden !important;
    transition: all 0.3s ease !important;
    left: 4px !important;
}

.grecaptcha-badge:hover {
    width: 256px !important;
}
